import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import {Router} from "@angular/router";
import {AuthService} from "../../../../../app/service/auth.service";
import {User} from "../../../../../app/model/user.model";

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {

  items: MenuItem[] = [
    {
      id: '1',
      icon: 'mat:account_circle',
      label: 'Mon Profile',
      description: 'Informations personnelles',
      colorClass: 'text-teal',
      route: '/modules/profil'
    },
    {
      id: '2',
      icon: 'mat:account_circle',
      label: 'Changer Mot de Passe',
      description: 'Modification',
      colorClass: 'text-teal',
      route: '/modules/change-password'
    }/*,
    {
      id: '2',
      icon: 'mat:move_to_inbox',
      label: 'My Inbox',
      description: 'Messages & Latest News',
      colorClass: 'text-primary',
      route: '/apps/chat'
    },
    {
      id: '3',
      icon: 'mat:list_alt',
      label: 'My Projects',
      description: 'Tasks & Active Projects',
      colorClass: 'text-amber',
      route: '/apps/scrumboard'
    },
    {
      id: '4',
      icon: 'mat:table_chart',
      label: 'Billing Information',
      description: 'Pricing & Current Plan',
      colorClass: 'text-purple',
      route: '/pages/pricing'
    }*/
  ];

  statuses: OnlineStatus[] = [
    {
      id: 'online',
      label: 'Online',
      icon: 'mat:check_circle',
      colorClass: 'text-green'
    },
    {
      id: 'away',
      label: 'Away',
      icon: 'mat:access_time',
      colorClass: 'text-orange'
    },
    {
      id: 'dnd',
      label: 'Do not disturb',
      icon: 'mat:do_not_disturb',
      colorClass: 'text-red'
    },
    {
      id: 'offline',
      label: 'Offline',
      icon: 'mat:offline_bolt',
      colorClass: 'text-gray'
    }
  ];

  activeStatus: OnlineStatus = this.statuses[0];
  user: User;

  trackById = trackById;
  currentUser: { role: string; id: number; prenom: string; nom: string };

  constructor(private cd: ChangeDetectorRef,private authService: AuthService,private router: Router,
              private popoverRef: PopoverRef<ToolbarUserDropdownComponent>) { }

  ngOnInit() {
    this.loadAuth();
    const userDetails = this.authService.getUserDetails();
    this.currentUser = {
      id: Number(userDetails.id),
      prenom: userDetails.prenom,
      nom: userDetails.nom,
      role: userDetails.role
    };
    console.log(userDetails)
  }

  loadAuth() {
    this.authService.me().subscribe(rs => {
      this.user = rs;
    })
  }

  setStatus(status: OnlineStatus) {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  close() {
    this.popoverRef.close();
  }

  logout() {
    this.authService.logout();
    this.close();
  }

  openModuleSysteme() {
    if (this.currentUser.role === 'admin') {
      this.router.navigate(['/modules/systeme'])
    }
  }
}
