import { Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { fadeInUp400ms } from '../../@vex/animations/fade-in-up.animation';
import { fadeInRight400ms } from '../../@vex/animations/fade-in-right.animation';
import { scaleIn400ms } from '../../@vex/animations/scale-in.animation';
import { stagger40ms } from '../../@vex/animations/stagger.animation';
import { OptionService } from '../modules/systeme/option/option.service';
import { PointDeVenteService } from '../modules/administration/point-de-vente/point-de-vente.service';
import { UserService } from '../modules/administration/espace-user/espace-user.service';
import { Option } from 'src/app/modules/systeme/option/option.interface';
import { PointDeVente } from '../../app/modules/administration/point-de-vente/point-de-vente.interface';
import { User } from '../modules/administration/espace-user/espace-user.interface';

@Component({
  selector: 'vex-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    scaleIn400ms,
    stagger40ms
  ]
})
export class ProfileComponent implements OnInit {


  user: User;
  options: string[];
  pointDeVentes: string[];

  constructor(
    private userService: UserService,private authService: AuthService,
    private optionService: OptionService,
    private pointDeVenteService: PointDeVenteService
  ) {}

  ngOnInit(): void {
    // @ts-ignore
    this.user = this.authService.me().subscribe(data => {
        this.user = data;
        this.optionService.getAll().subscribe((data: Option[]) => {
          this.options = data.filter(
            option => this.user.menuEspaceUser.options.includes(option.id.toString())
          ).map(option => option.designation);
        });
        this.pointDeVenteService.getAll().subscribe((data: PointDeVente[]) => {
          this.pointDeVentes = data.filter(
            point => this.user.detailEspaceUser.pointDeVentesIds.includes(point.id.toString())
          ).map(point => point.libelle);
        });
      });
    }
  }

