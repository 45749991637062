import { Injectable } from '@angular/core';
import { NavigationDropdown, NavigationItem, NavigationLink, NavigationSubheading } from '../interfaces/navigation-item.interface';
import { Subject } from 'rxjs';

import systemeRoutes from "./routes/systeme.routes.json";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Option } from 'src/app/modules/systeme/option/option.interface';
import { ModuleNavigation, normalizeRoute } from 'src/app/model/user-navigation.interface';
import { Queue } from 'src/app/utils/queue';
import { SousOption } from 'src/app/modules/systeme/sous-option/sous-option.interface';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  items: NavigationItem[] = [];
  private _itemsChangeSubject = new Subject<NavigationItem[]>();
  itemsChange$ = this._itemsChangeSubject.asObservable();

  private _openChangeSubject = new Subject<NavigationDropdown>();
  openChange$ = this._openChangeSubject.asObservable();

  private _options = new Subject<any>();
  optionsItems$ = this._options.asObservable();

  private options: Option[] = [];

  private _sousOptions = new Subject<any>();
  sousOptionsItems$ = this._sousOptions.asObservable();

  private sousOptions: SousOption[] = [];

  private allowedOptions: Option[] = [];
  constructor(
    private http: HttpClient
  ) { }

  private url = environment.base_url+ '/administration/options';
  private sousOptionUrl = environment.base_url+ '/administration/sous-options';

  userModules: any[] = [];
  tasks: Queue<{
    name: string,
    resolve: (value: any) => void,
  }> = new Queue();

  getAllowedOptions() {
    return this.allowedOptions;
  }

  setAllowedOptions(options: Option[]) {
    this.allowedOptions = options;
  }

  getModules() {
    this.tasks.enqueue({
      name: "Récupération des options",
      resolve: (_) => {
        this.getOptions();
      }
    });
    this.tasks.enqueue({
      name: "Récupération des sous-options",
      resolve: (_) => {
        this.getSousOptions();
      }
    });
    this.tasks.enqueue({
      name: "Inclusion des sous-options aux options",
      resolve: (_) => {
        this.includeSousOptionsToOption();
      }
    });

    let task = this.tasks.dequeue();
    task.resolve(null);
  }

  includeSousOptionsToOption() {
    let access = localStorage.getItem('options');
    this.options.forEach(option => {
      option.sousOptions = this.sousOptions.filter(sousOption => sousOption.option.id === option.id);
    });
    this._options.next(this.options.filter(option => access.includes(option.id.toString())));
  }

  getOptions() {
    this.http.get(this.url + "/all").subscribe({
      next: (options: Option[]) => {
        this.options = options;
      },
      error: (err) => {
      },
      complete: () => {
        if (!this.tasks.isEmpty()) {
          let task = this.tasks.dequeue();
          task.resolve(null);
        }
      }
    });
  }

  getSousOptions() {
    this.http.get(this.sousOptionUrl + "/all").subscribe({
      next: (options: SousOption[]) => {
        this.sousOptions = options;
      },
      error: (err) => {
      },
      complete: () => {
        if (!this.tasks.isEmpty()) {
          let task = this.tasks.dequeue();
          task.resolve(null);
        }
      }
    });
  }

  triggerOpenChange(item: NavigationDropdown) {
    this._openChangeSubject.next(item);
  }

  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === 'link';
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown';
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading';
  }

  triggerItemsChange(module: string) {
    const items = this.getModule(module);
    this._itemsChangeSubject.next(items);
  }


  getUserModules() {
    this.getModules();
  }

  private modules: ModuleNavigation[] = [];

  setModules(modules: ModuleNavigation[]) {
    this.modules = modules;
    localStorage.setItem('modules', JSON.stringify(modules));
  }

  getModule(module: string): NavigationItem[] {
    if (this.modules.length === 0) {
      this.modules = JSON.parse(localStorage.getItem('modules'));
    }
    switch (module) {
      case "systeme": return systemeRoutes.routes as NavigationItem[];
      default: return this.modules.find(m => normalizeRoute(m.name.toLowerCase()) === normalizeRoute(module)).nav;
    }
  }
}
