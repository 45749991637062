import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/@vex/services/navigation.service';
import { Option } from 'src/app/modules/systeme/option/option.interface';
import { ModuleNavigation, normalizeRoute } from './../model/user-navigation.interface';

interface Module {
  id: number;
  name: string;
}


@Component({
  selector: 'vex-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private cdr: ChangeDetectorRef
  ) {}

  modules: Module[] = [];
  options: Option[] = [];

  items = [
    {
      titre: 'Administration',
      module: 'administration',
      icon: 'admin__icon.png',
      active: false,
      class: 'card w-72 flex-1 flex flex-col items-center cursor-not-allowed grayscale'
    },
    {
      titre: 'Assuré',
      module: 'assuré',
      icon: 'assure__icon.png',
      active: false,
      class: 'card w-72 flex-1 flex flex-col items-center cursor-not-allowed grayscale'
    },
    {
      titre: 'Paramétrage Auto',
      module: 'paramétrage-auto',
      icon: 'param_icon.png',
      active: false,
      class: 'card w-72 flex-1 flex flex-col items-center cursor-not-allowed grayscale'
    },
    {
      titre: 'Exploitation Auto',
      module: 'exploitation-auto',
      icon: 'exp__icon.png',
      active: false,
      class: 'card w-72 flex-1 flex flex-col items-center cursor-not-allowed grayscale'
    },
    {
      titre: 'Paramétrage TIRD',
      module: 'paramétrage-tird',
      icon: 'param_rd__icon.png',
      active: false,
      class: 'card w-72 flex-1 flex flex-col items-center cursor-not-allowed grayscale'
    },
    {
      titre: 'Exploitation TIRD',
      module: 'exploitation-tird',
      icon: 'rd__icon.png',
      active: false,
      class: 'card w-72 flex-1 flex flex-col items-center cursor-not-allowed grayscale'
    },
    {
      titre: 'Paramétrage ADP',
      module: 'paramétrage-adp',
      icon: 'param_adp__icon.png',
      active: false,
      class: 'card w-72 flex-1 flex flex-col items-center cursor-not-allowed grayscale'
    },
    {
      titre: 'Exploitation ADP',
      module: 'exploitation-adp',
      icon: 'adp_icon.png',
      active: false,
      class: 'card w-72 flex-1 flex flex-col items-center cursor-not-allowed grayscale'
    },
    {
      titre: 'Comptabilité',
      module: 'comptabilité',
      icon: 'compta__icon.png',
      active: false,
      class: 'card w-72 flex-1 flex flex-col items-center cursor-not-allowed grayscale'
    },
    {
      titre: 'Réassurance',
      module: 'réassurance',
      icon: 'reas__icon.png',
      active: false,
      class: 'card w-72 flex-1 flex flex-col items-center cursor-not-allowed grayscale'
    }
  ];

  openModule(moduleName: string) {
    if (this.items.find(
      item => normalizeRoute(item.module.toLocaleLowerCase()) === normalizeRoute(moduleName.toLocaleLowerCase())
    ).active) {
      localStorage.setItem('module', moduleName);
      this.navigationService.triggerItemsChange(normalizeRoute(moduleName));
      this.router.navigate(['/modules/' + normalizeRoute(moduleName)]);
    }
  }

  ngOnInit() {

    this.modules = JSON.parse(localStorage.getItem('modules-home'));

    if (this.modules) {
      this.items.forEach(item => {
        if (this.modules.find(module => normalizeRoute(module.name.toLocaleLowerCase()) === normalizeRoute(item.module.toLocaleLowerCase()))) {
          item.active = true;
          item.class = 'card w-72 flex-1 flex flex-col items-center cursor-pointer';
        }
      });
    }


    this.navigationService.optionsItems$.subscribe((options: Option[]) => {
      if (options) {
        this.options = options;
        this.modules = this.options.map(option => {
          return {
            id: option.moduleId,
            name: option.module,
            sousOptions: option.sousOptions
          }
        }).filter((module, index, self) =>
          index === self.findIndex((t) => (
            t.id === module.id
          ))
        );

        localStorage.setItem('modules-home', JSON.stringify(this.modules));

        this.items.forEach(item => {
          if (this.modules.find(module => normalizeRoute(module.name.toLocaleLowerCase()) === normalizeRoute(item.module.toLocaleLowerCase()))) {
            item.active = true;
            item.class = 'card w-72 flex-1 flex flex-col items-center cursor-pointer';
          }
        });

        const modulesNav:ModuleNavigation[] = this.modules.map(module => new ModuleNavigation(
          module.name,
          this.options.filter(option => option.module === module.name)
        ));
        this.navigationService.setModules(modulesNav);

        this.cdr.detectChanges();
      }
    });
  }


}
