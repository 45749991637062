import { NgModule } from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { HomeComponent } from './home/home.component';
import { authGuard } from './auth.guard';
import { ProfileComponent } from './profile/profile.component';

const routes: Routes = [
	{
		path: "",
		canActivate: [authGuard],
		component : HomeComponent
	},
	{
		path: 'modules',
		component: CustomLayoutComponent,
		canActivate: [authGuard],
		loadChildren: () => import('./modules/all.module').then(m => m.AllModule)
	},
	{
		path: "auth",
		loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
	},
	{
		path: "**",
		redirectTo: ""
	}

];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
		preloadingStrategy: PreloadAllModules,
		scrollPositionRestoration: 'enabled',
		anchorScrolling: 'enabled'
	})],
	exports: [RouterModule]
})

export class AppRoutingModule {}
