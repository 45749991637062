import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PointDeVente } from './point-de-vente.interface';

@Injectable({
  providedIn: 'root'
})
export class PointDeVenteService {

  constructor(private http: HttpClient) { }

  private urlService = `${environment.base_url}/administration/points-de-ventes`;
  private urlService2 = `${environment.base_url}/administration/points-de-ventes/`;

  getAll(): any {
    return this.http.get<any>(this.urlService+'/all');
  }

  getByCompagnie(compagnie: any): any {
    return this.http.get<any>(this.urlService+'/compagnie/'+compagnie);
  }

  save(pointDeVente: PointDeVente): Observable<any> {
    return this.http.post<any>(this.urlService, pointDeVente);
  }

  update(pointDeVente: PointDeVente): Observable<any> {
    return this.http.put<any>(this.urlService2 + pointDeVente.id, pointDeVente);
  }

  getById(pointDeVente: any): Observable<any> {
    return this.http.get<any>(this.urlService2 + pointDeVente);
  }

  delete(pointDeVente: any): Observable<any> {
    return this.http.delete<any>(this.urlService2 + pointDeVente.id);
  }

}
