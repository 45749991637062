
import { NavigationDropdown, NavigationItem, NavigationLink, } from 'src/@vex/interfaces/navigation-item.interface';
import { Option } from '../modules/systeme/option/option.interface';
import { SousOption } from '../modules/systeme/sous-option/sous-option.interface';

export class SousOptionNavigation {
    id: number;
    code: string;
    nav: NavigationLink;

    constructor(sousOption: SousOption) {
        this.id = sousOption.id || 0;
        this.code = sousOption.code || '';
        let route = `/modules/${sousOption.option.module.toLowerCase()}/${sousOption.option.designation.toLowerCase()}/${sousOption.designation.toLowerCase()}`;
        if (sousOption.designation.includes("/")) route = normalizeRoute(route, true);
        else route = normalizeRoute(route);

        this.nav = {
            type: "link",
            label: sousOption.designation || "",
            route,
            routerLinkActiveOptions: {"exact": true}
        }
    }
}

export class OptionNavigation  {
    id: number;
    code: string;
    nav: NavigationLink | NavigationDropdown;

    constructor(option: Option) {
        this.id = option.id || 0;
        this.code = option.code || '';
        let route = `/modules/${option.module.toLowerCase()}/${option.designation.toLowerCase()}`;

        if (option.sousOptions && option.sousOptions.length > 0) {
            this.nav = {
                type: "dropdown",
                label: option.designation || "",
                children: option.sousOptions.map(sousOption => new SousOptionNavigation(sousOption).nav)
            }
        } else {
            if (option.designation.includes("/")) route = normalizeRoute(route, true);
            else route = normalizeRoute(route);

            this.nav = {
                type: "link",
                label: option.designation || "",
                route,
                routerLinkActiveOptions: {"exact": true}
            }
        }
    }
}

export function normalizeRoute(route: string, formatSlash = false) {
    const map = {
        " ": "-",
        "é": "e", "è": "e", "ê": "e", "ë": "e",
        "à": "a", "â": "a",
        "ç": "c",
        "ô": "o", "ö": "o",
        "û": "u", "ù": "u", "ü": "u",
        "î": "i", "ï": "i"
    };
    if (formatSlash) route = route.replace(/\/(?!.*\/)/, "-");
    return route.replace(/[\séèêëàâçôöûùüîï]/g, match => map[match]);
}

export class MenuNavigation {

    name: string;
    type: 'subheading' | 'dropdown';
    nav: NavigationItem;
    options: OptionNavigation[];

    constructor(menu: string, type: 'subheading' | 'dropdown', options: Option[]) {
        this.name = menu;
        this.type = type;
        this.options = options.map(option => new OptionNavigation(option));
        this.nav = {
            type: type,
            label: menu,
            children: this.options.map(option => option.nav)
        }
    }
}

export class ModuleNavigation {
    name: string;
    menus: MenuNavigation[];
    nav: NavigationItem[];

    constructor(module: string, options: Option[]) {
        this.name = module;
        this.menus = [];
        let menus = options.sort((a, b) => a.rangMenu - b.rangMenu).map(option => option.menu);
        menus = [...new Set(menus)];

        menus.forEach(menu => {
            this.menus.push(new MenuNavigation(menu, "dropdown" ,options.filter(option => option.menu === menu)));
        });

      console.log(menus)

        this.nav = this.menus.map(menu => menu.nav);
    }
}
