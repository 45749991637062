<div class="card overflow-auto flex flex-col md:flex-row sm:mr-6">

  <div class="p-gutter flex-auto">
    <h2 class="headline mb-4 text-primary">
      Olive Assure
    </h2>

    <div class="mt-4 flex gap-4">
      <div
        *ngFor="let feature of features"
        [ngClass]="feature.class"
      >
        <a (click)="onNavigate(feature.name)"
          class="text-center">
          <img [src]="feature.icon" class="w-32" />
          <div class="body-2 mt-2">{{ feature.label }}</div>
        </a>
      </div>
    </div>
  </div>
</div>

