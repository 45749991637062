import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { Observable } from 'rxjs';
import { NavigationItem } from 'src/@vex/interfaces/navigation-item.interface';
import { Option } from 'src/app/modules/systeme/option/option.interface';

@Component({
  selector: 'vex-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  items: NavigationItem[];
  modules: Observable<any>;

  constructor(
    private navigationService: NavigationService
  ) { }

  ngOnInit() {
    this.navigationService.itemsChange$.subscribe((items) => {
      this.items = items;
    });
    this.navigationService.optionsItems$.subscribe((options) => {
      this.modules = options;
    });
  }
}
