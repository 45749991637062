import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from './espace-user.interface';
import { Observable } from 'rxjs';
import { MenuEspaceUser } from './menu-espace-user/menu-espace-user.interface';
import { DetailsEspaceUser } from './details-espace-user/details-espace-user.interface';
import { CompagniesUser } from './compagnies-user/compagnies-user.interface';
import { AuthService } from 'src/app/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {


  constructor(
    private http: HttpClient,
  ) { }

  private urlService = `${environment.base_url}/auth/users`;
  private urlServiceMEU = `${environment.base_url}/auth/menu-espace-users`;
  private urlServiceDEU = `${environment.base_url}/auth/detail-espace-users`;
  private urlServiceCU = `${environment.base_url}/auth/categorie-users`;

  private urlServiceCompagnie = `${environment.base_url}/auth/find/compagnie`;

  getAll(): any {
    return this.http.get<any>(this.urlService+"/all");
  }

  getByCompagnie(compagnie: string): any {
    return this.http.get<any>(this.urlServiceCompagnie +"/"+ compagnie);
  }

  addCompagnieUser(data:{userId:string, compagnies: string[]}): Observable<any> {
    
    return this.http.put<any>(this.urlService+"/update/compagnies/" + data.userId, data);
  }

  addProduitUser(produitIds: any) {
    return this.http.post<any>(this.urlServiceCU, produitIds);
  }

  addMenuEspaceUser(meu: MenuEspaceUser): Observable<any> {
    return this.http.post<any>(this.urlServiceMEU, meu);
  }

  addDetailEspaceUser(deu: DetailsEspaceUser): Observable<any> {
    return this.http.post<any>(this.urlServiceDEU, {
      userId: deu.userId,
      pointsDeVentesIds: deu.pointDeVentesIds
    });
  }

  save(user: User): Observable<any> {
    return this.http.post<any>(this.urlService, user);
  }

  update(user: User): Observable<any> {
    return this.http.put<any>(this.urlService+"/" + user.id, user);
  }

  addProfilUser(userId: number, data: any): Observable<any> {
    return this.http.put<any>(this.urlService+"/update/roles/" + userId, data);
  }

  getById(user: any): Observable<any> {
    return this.http.get<any>(this.urlService +"/find/"+ user);
  }

  delete(user: any): Observable<any> {
    return this.http.delete<any>(this.urlService +"/delete/"+ user.id);
  }

  getByUsername(username: string): Observable<any> {
    return this.http.get<any>(this.urlService +"/find/username/"+ username);
  }
}
