import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Option } from './option.interface';

@Injectable({
  providedIn: 'root'
})
export class OptionService {

  constructor(private http: HttpClient) { }

  private urlService = `${environment.base_url}/administration/options`;
  private urlService2 = `${environment.base_url}/administration/options/`;

  getAll(): any {
    return this.http.get<any>(this.urlService+'/all');
  }

  save(option: Option): Observable<any> {
    return this.http.post<any>(this.urlService, option);
  }

  update(option: Option): Observable<any> {
    return this.http.put<any>(this.urlService2 + option.id, option);
  }

  getById(option: any): Observable<any> {
    return this.http.get<any>(this.urlService2 + option);
  }

  delete(option: any): Observable<any> {
    return this.http.delete<any>(this.urlService2 + option.id);
  }

}
