<vex-page-layout>
  <div class="max-h-screen py-8 space-y-8 overflow-auto ">

    <div class="bg-foreground flex flex-col px-gutter">
      <h1 class="display-1 mt-4 mb-4 text-center text-[#0AB1CB]">Olive Insurance</h1>
      <h2 class="subheading-2 text-hint text-center max-w-lg mx-auto m-0">Modules de l'application</h2>
    </div>

    <div class="mx-auto w-fit gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 px-gutter">

      <div *ngFor="let item of items" 
        [ngClass]="item.class"
        (click)="openModule(item.module)"
      >
        <div class="w-full h-36 overflow-hidden text-primary bg-[#AFC3C8] flex items-center justify-center">
          <img class="object-cover h-32 relative" [src]="'assets/img/icons/'+item.icon" [alt]="item.titre">
        </div>
        <div class=" text-center">
          <h2 class="text-lg font-semibold my-2">{{ item.titre }}</h2>
        </div>
      </div>
    </div>
  </div>
</vex-page-layout>


