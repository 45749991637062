<div class="mt-6 flex flex-col md:flex-row md:items-start">
  <div class="flex-auto">
    <div class="card">
      <div class="px-gutter py-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div class="my-6">
          <h2 class="mb-2 text-lg">
            Profil de {{ user.prenom }} {{ user.nom }}</h2>
        </div>
        <mat-divider></mat-divider>

        <div class="flex flex-col sm:flex-row mt-4">
          <mat-form-field appearance="outline" class="vex-flex-form-field flex-auto">
            <mat-label>Prenom</mat-label>
            <input matInput [value]="user.prenom" readonly>
          </mat-form-field>

          <mat-form-field appearance="outline" class="vex-flex-form-field sm:ml-6 flex-auto">
            <mat-label>Nom</mat-label>
            <input matInput [value]="user.nom" readonly>
          </mat-form-field>
        </div>

        <div class="flex flex-col sm:flex-row mt-4">
          <mat-form-field appearance="outline" class="vex-flex-form-field flex-auto">
            <mat-label>Téléphone</mat-label>
            <input matInput [value]="user.telephone" readonly>
          </mat-form-field>

          <mat-form-field appearance="outline" class="vex-flex-form-field sm:ml-6 flex-auto">
            <mat-label>Email</mat-label>
            <input matInput [value]="user.email" readonly>
          </mat-form-field>
        </div>

        <div class="flex mt-4">
          <mat-form-field appearance="outline" class="vex-flex-form-field flex-auto">
            <mat-label>Entité de gestion</mat-label>
            <input matInput [value]="user.entiteDeGestion" readonly>
          </mat-form-field>
        </div>

        <div class="flex mt-4">
          <mat-form-field appearance="outline" class="vex-flex-form-field flex-auto">
            <mat-label>Etes vous un courtier ?</mat-label>
            <input matInput [value]="user.lier ? 'Non' : 'Oui'" readonly>
          </mat-form-field>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="px-gutter py-4 grid grid-cols-1 sm:grid-cols-2 gap-4">

        <!-- Affichage des compagnies associées -->
        <div *ngIf="user.compagnies?.length > 0" class="my-6">
          <h2 class="mb-2 text-lg">
            Compagnies associées</h2>
          <div class="flex flex-wrap gap-4">
            <div *ngFor="let compagnie of user.compagnies" class="bg-gray-100 p-2 py-1 rounded">
              {{ compagnie }}
            </div>
          </div>
        </div>

        <mat-divider></mat-divider>

        <!-- Affichage des points de vente -->
        <div *ngIf="user.detailEspaceUser?.pointDeVentesIds?.length > 0" class="my-6">
          <h2 class="mb-2 text-lg">
            Points de vente associés</h2>
          <div class="flex flex-wrap gap-4">
            <div *ngFor="let pointDeVente of pointDeVentes" class="bg-gray-100 p-2 py-1 rounded">
              {{ pointDeVente }}
            </div>
          </div>
        </div>

        <mat-divider></mat-divider>

        <!-- Affichage des options -->
        <div *ngIf="user.menuEspaceUser?.options?.length > 0" class="my-6">
          <h2 class="mb-2 text-lg">
            Options associées</h2>
          <div class="flex flex-wrap gap-4">
            <div *ngFor="let option of options" class="bg-gray-100 p-2 py-1 rounded">
              {{ option }}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
