import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PoolSessionService {
  private tokenKey = 'pool_token';

  constructor() {}

  // Stocker le token dans le sessionStorage
  setToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  // Récupérer le token depuis le sessionStorage
  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }
  clearToken(): void {
    sessionStorage.removeItem(this.tokenKey);
  }

  clearSession(): void {
    localStorage.clear();
    sessionStorage.clear();
  }
}
